
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Roboto:wght@100;300;500&display=swap');





.aboutus{
  padding-top: 4rem;



}
.header{
    width: 100%;
    line-height: 2.1;
}
.about{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutus .header h1{

  text-transform: uppercase;
  color: black;
}
.header p{
  color: black;
  text-align: left;
  /* text-align: center; */
  font-size: 1.3em;
  margin: 0 4rem;


}
.contactform{
    width: 670px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;


}
.form-control {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
}
.form-control:active, .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #000;
}
.content {

}
.container_aboutus{
  z-index: 0;
  width: 30rem;
    height: 100%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  }
  .container .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container .content .left-side{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content .left-side::before{
    content: '';

    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .content .left-side .details{
    margin: 14px;
    text-align: center;
  }
  .content .left-side .details i{
    font-size: 30px;
    color: #3e2093;
    margin-bottom: 10px;
  }
  .content .left-side .details .topic{
    font-size: 18px;
    font-weight: 500;


  }
   .right-side  p {
    color: #757589 !important;
  }
  .content .left-side .details .text-one,
  .content .left-side .details .text-two{
    font-size: 14px;
    color: #afafb6;
  }
  .container .content .right-side{
    width: 75%;
    margin-left: 75px;
  }
  .content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #3e2093;
  }
  .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 22px 0;
  }
  .right-side .input-box input,
  .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .right-side .message-box{

  }
  .right-side .input-box textarea{
    padding-top: 6px;
  }
  .right-side .button{
    display: inline-block;
    margin-top: 12px;
  }
  .right-side .button input[type="button"]{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
        cursor: pointer;
    transition: all 0.3s ease;
  }
  .button input[type="button"]:hover{
    background: #5029bc;
  }
  

  #tsparticles{
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
    position:absolute !important;
    z-index: -100000 !important;
    /* top: 0px !important; */
    left: 0px !important;
    background-color: rgb(255 255 255);
  }





.button-34 {
  background: #5E5DF0;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}



/* CSS */
.button-69 {
  background-color: initial;
  background-image: linear-gradient(#8614f8 0, #760be0 100%);
  border-radius: 5px;
  border-style: none;
  box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  line-height: 53px;
  margin-left: -4px;
  outline: 0;
  text-align: center;
  transition: all .3s cubic-bezier(.05, .03, .35, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: bottom;
  width: 250px;
}

.button-69:hover {
  opacity: .7;
}



.whatwedo_text{
  line-height: 2;
  font-size: 1.4em;
  margin: 1rem 4rem;
}


@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .aboutus .header h1 {
    font-size: 0.8em;
    text-transform: uppercase;
    color: black;
}
.whatwedo_text {
  line-height: 2;
  margin: 0px 2rem;
  font-size: 1.0em;
}
.header p {
  color: black;
  text-align: left;
  /* text-align: center; */
  font-size: 1.0em;
  margin: 0 2rem;
}
  
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) {
  
  .aboutus .header h1 {
    font-size: 0.8em;
    text-transform: uppercase;
    color: black;
}
.bu_submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0rem 3rem 0rem;
}
.whatwedo_text {
  line-height: 2;
  margin: 0 2rem;
  font-size: 1.2em;

}
.aboutus {
  padding-top: 0rem;
}
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { /* big landscape tablets, laptops, and desktops */ }