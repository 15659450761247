

@media all and (max-width: 629px) { 
    .retail .text_container {
        width: 100% !important;
    }
    .container_card {
        max-width: 1000px;
        display: flex;
        margin: 0 auto;
        flex-direction: column !important;
    }
    .container_card_healthcare {
        max-width: 1000px;
        display: flex;
        margin: 0 auto;
        flex-direction: column !important;
        align-items: center !important;
    }
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 630px){ /* tablet, landscape iPad, lo-res laptops ands desktops */ }

.verticals_main{
    background-color: white;
}
.verticals_main .header_verticals{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    padding-top: 9rem;
    margin-bottom: 4rem;
}
.retail{
    padding: 3rem;
}
.verticals_main .header h1{
 font-size: 3.5em;
}

.retail .container_card  h3{
    font-size: 2.5em;
   }
.retail{
    display: flex;
    flex-direction: row;
}
.retail .text_container{
    width: 80%;
}
.retail .text_container p{
    color: gray;
    font-size: 1.2rem;
    padding-top: 1rem;
    
}
.container_card{
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    flex-direction: row;

}
.retail_img{

    width: 30%;
    margin-left: 3rem;
}

.healthcare{

 
/* background-color: #F8FFF8; */
 
    display: flex;
    flex-direction: row;
}
.healthcare .text_container_healthcare{
    width: 80%;
}
.healthcare .container_card_healthcare h3{
    padding-top: 1rem;
    font-size: 2.5em;
   }
.healthcare  .text_container_healthcare p{
    color: gray;
    font-size: 1.2rem;
    padding-top: 1rem;
    
}
.container_card_healthcare{
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    flex-direction: row;

}
.retail_img{

    width: 15rem;
    margin-left: 3rem;
}


.container_card_healthcare{
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;

}

@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  {
    .header_verticals  h1 {
        text-transform: uppercase;
        color: black;
        font-size: 1.8em;
    }
    .retail .text_container p {
        color: gray;
        font-size: 1rem;
        padding-top: 1rem;
    }
    .healthcare .text_container_healthcare p {
        color: gray;
        font-size: 1rem;
        padding-top: 1rem;
    }
    
    .retail .container_card h3 {
        font-size: 1.5em;
    }

.healthcare .container_card_healthcare h3 {
    padding-top: 1rem;
    font-size: 1.5em;
}
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) {
    .header_verticals  h1 {
        text-transform: uppercase;
        color: black;
        font-size: 2em;
    }
    
    .retail .container_card h3 {
        font-size: 1.5em;
    }

.healthcare .container_card_healthcare h3 {
    padding-top: 1rem;
    font-size: 1.5em;
}
    
.container_card {
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}
.container_card_healthcare {
    max-width: 1000px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){
    
    
    .header_verticals  h1 {
        text-transform: uppercase;
        color: black;
        font-size: 2em;
    }
    
    .retail .container_card h3 {
        font-size: 1.5em;
    }

.healthcare .container_card_healthcare h3 {
    padding-top: 1rem;
    font-size: 1.5em;
}/* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { 
    .header_verticals  h1 {
        text-transform: uppercase;
        color: black;
        font-size: 3em;
    }
    
    /* big landscape tablets, laptops, and desktops */ }