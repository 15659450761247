/*  */

.contact-form{
  
}

.contact-form-id h1{
    color: white;
    padding: 4rem 0 2rem 0 ;
    width: 70%;
}
.input_contact{
  
}
.form-control {
  margin-top: 5rem;
  background-color: #1b2c49;
}


.form-select{
  width: 48%;
  background-color: #1b2c49;
  margin-top: 4rem;

  color: white;
  font-size: 1.5em;
}
.form-control:valid {
  background-color:  #1b2c49!important;
  border-bottom: 1px solid #d9d9d9;
  color: white;
}

.bu_submit{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem 0rem 3rem 0rem;
}
.input, .textarea {
  background-color: #1b2c49;

}

.form_contact{

  
}
.input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;

}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;

}
.is-large.input, .is-large.textarea {
  font-size: 1.5rem;
  outline: 0;
  width: 83%;
  border-width: 0 0 2px;
  border-color: white;
}
/* CSS */
.button-34 {
  margin-top: 2rem;
  background: #5E5DF0;
  border-radius: 999px;
  box-shadow: #5E5DF0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 9px 46px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}
.select_color{
  color: white;
  opacity: 1; /* Firefox */
  
}
.select_color option{
  font-size: 1.5rem;
  outline: 0;
  width: 80%;
  border-width: 0 0 2px;
  border-color: white;
}
.select {

}
.select select {
  border-width: 0 0 2px;
  border-color: white;
  cursor: pointer;
  display: block;
  font-size: 1em;
  width: 26rem;
  outline: 0;
  color: white;
  
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: white;
  right: 1.125em;
  z-index: 4;
}
.header_contact h1{
  padding-top: 5rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .header_contact h1 {
    font-size: 1.6em;
    text-transform: uppercase;
    color:white;
}
.bu_submit {
  display: flex;
  align-items: center;
  padding: 4rem 0rem 3rem 0rem;
  flex-direction: column;
}

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
  .header_contact h1 {
    font-size: 1.6em;
    text-transform: uppercase;
    color:white;
}
.bu_submit {
  display: flex;
  align-items: center;
  padding: 4rem 0rem 3rem 0rem;
  flex-direction: column;
}
.contact{
  margin: 30px;
}

  
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
  
  .header_contact h1 {
    font-size: 1.6em;
    text-transform: uppercase;
    color:white;
}
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { 
  .header_contact h1 {
    font-size: 1.6em;
    text-transform: uppercase;
    color:white;
}
  /* big landscape tablets, laptops, and desktops */ }