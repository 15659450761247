:root {
	--mainColor: white;
	--mainColorLight: white;
	--secondaryColor: #db2b39;
	--textColor: black;
}

header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    z-index: 10000;
    width: 100%;
}
.logo{
    width: 12rem;
}
nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
    width: 100%;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: center;
}



.button-60 {
    background-color: initial;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
    border-radius: 5px;
    border-style: none;
    box-shadow: rgb(245 244 247 / 25%) 0 1px 1px inset;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 37px;
    line-height: 38px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
}

.button-60:hover {
  opacity: .7;
}

@media screen and (max-width: 1000px) {
  .button-60 {
    font-size: 14px;
    height: 55px;
    line-height: 55px;
    width: 150px;
  }
}
@media only screen and (max-width: 1024px) {
	header .nav-btn {
        z-index: 100000;
		visibility: visible;
		opacity: 1;
	}

	header nav {
    z-index: 1000000000 !important;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		transform: translateY(-100vh);
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}