
.aboutus_main{
}

.aboutus_header{
    width: 100%;
}

.aboutus_main h1{
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.aboutus_main p{
    text-align: left;
    font-size: 1.2rem;
    
    
}
.aboutus_text{

}

@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  {
    .aboutus_main h1 {
        color: black;
        display: flex;
        font-size: 1.8em;
        padding: 3rem;
        flex-direction: column;
        align-items: center;
    }
    .aboutus_main p {
        margin: 1rem;
        text-align: left;
        font-size: 1.1rem;
        padding-bottom: 2rem;
    }
    
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) {
    
    .aboutus_main h1 {
        color: black;
        display: flex;
        font-size: 1.8em;
        padding: 3rem;
        flex-direction: column;
        align-items: center;
    }
    .aboutus_main p {
        margin: 1rem;
        text-align: left;
        font-size: 1.1rem;
        padding-bottom: 2rem;
    }
    
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
    .aboutus_main h1 {
        color: black;
        display: flex;
        font-size: 3em;
        padding: 3rem;
        flex-direction: column;
        align-items: center;
    }
    .aboutus_main p {
        margin: 1rem;
        text-align: left;
        font-size: 1.2rem;
      
    }
 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { 
    .aboutus_main h1 {
        color: black;
        display: flex;
        font-size: 3em;
        padding: 3rem;
        flex-direction: column;
        align-items: center;
    }
    .aboutus_main p {
        margin: 1rem;
        text-align: left;
        font-size: 1.2rem;
      
    }
    
    /* big landscape tablets, laptops, and desktops */ }