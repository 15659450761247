@media all and (max-width: 539px) {  
  .ourprocess .header h2 {
    font-size: 2.2rem !important;

}
.ourprocess .header p {

  font-size: 1.2em !important;
}
.info-box-section {
  border-radius: 40px;
  box-shadow: 0 21.2459px 65.0656px rgba(1,44,109,.16) !important;
  height: 58vh !important;
  margin: 0 20px;
  width: 77vw !important;
}
  /* smartphones, iPhone, portrait 480x320 phones */ }
  .info-box-section-content {
    box-shadow: 0 21.2459px 65.0656px rgb(1 44 109 / 16%) !important;

  }

.ourprocess{
    padding-top: 2rem;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ourprocess .header h2 {
  font-size: 2.5rem !important;
  text-align: center;
}
.container_ourprocess{
  max-width: 1160px;
  margin: 0 auto;
}
.ourprocess .header{
 
    display: flex;
    /* width: 70%; */
    flex-direction: column;
    align-items: center;
    padding-top: 9rem;
    margin-bottom: 4rem;
    
}
.ourprocess .header h2{
 
  font-size: 3.5rem;
  text-align: center;
}
.ourprocess .header p{
  color: black;
  text-align: left;
  /* text-align: center; */
  font-size: 1.3em;
    }
    
    .info-box-parent {
        margin-top: 55px;
      /*   display: flex;
        justify-content: center; */
      }
      
      @media only screen and (min-width: 1051px) {
        .info-box-parent {
          display: flex;
          padding-bottom: 7rem;
          justify-content: center;
        }
      }
      
      @media only screen and (max-width: 1050px) {
        .info-box-parent {
          text-align: center;
        }
      }
      
      .info-box-section {

        width: 280px;

    height: 548px;
      /*     display: flex; */
      /*     flex-direction: column; */
      /*     align-items: center; */
      /*     background: transparent; */
          border-radius: 40px;
          box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
          margin: 0 20px;
      }
      
      @media only screen and (max-width: 1050px) {
        .info-box-section {
          display: inline-block;

    margin-top: 3rem !important;

        }
      }
      
      .info-box-section-border{
          height: 10px;
          width: 100%;
          border-top-left-radius: 40px;
          border-top-right-radius: 40px;
          background: #008FC8;
      }
      
      .info-box-section-content {
          background: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          height: 100%;
          padding: 0 10px;
          max-width: 100%;
      }
      
      .info-box-section-img {
        width: 100%;
        height: 22vh;
        margin: 13px 30px;
      }
      
      .info-box-section-title {
          font-size: 22px;
          font-weight: 600;
          color: #282828;
          margin: 0 auto;
          text-align: center;
      }
      
      .info-box-section-desc {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          line-height: 180%;
          text-align: center;
          color: #777777;
      }
      .container_ourprocess h1{
        text-transform: uppercase;
        color: black;
        display: flex;
        font-size: 3em;
        flex-direction: column;
        align-items: center;
      }

      @media all and (max-width: 639px) and (min-width: 320px)  
      
      
      { 


        .ourprocess .header p {
          font-size: 1em;
      }
        .container_ourprocess h1 {
          text-transform: uppercase;
          color: black;
          display: flex;
          font-size: 1.5em;
          flex-direction: column;
          align-items: center;
      }
        
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
  
  .container_ourprocess h1 {
    text-transform: uppercase;
    color: black;
    display: flex;
    font-size: 3em;
    flex-direction: column;
    align-items: center;
}
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){
  
  
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }