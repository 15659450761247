.our_expertise_main{

}
.ourexpertise{

    display: flex;
    flex-direction: column;
    align-items: center;
}
.ourexpertise .header{

    text-align: center;
    padding-top: 9rem;
    margin-bottom: 4rem;

}

.ourexpertise .header h1{
text-transform: uppercase;

color: black;

}
.container_ex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: column;
  align-items: center;



}
.card_ex {
  width: 300px;

  box-shadow:  rgba(0, 0, 0, 0.5);
}
.card_ex:hover .card__caption {
  top: 50%;
  transform: translateY(-50%);
}
.card_ex:hover .card__image {
  transform: translateY(-10px);
}
.card_ex:hover .card__thumb::after {
  top: 0;
}
.figure{
  margin: 0px 0 0rem !important;
}
.card_ex:hover .card__snippet {
  margin: 20px 0;
}
.card__thumb {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .card__thumb {
    max-height: 500px;
 }
}
.card__thumb:after {
  position: absolute;
  top: 0;
  /* display: block; */
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, .5) 40%, rgba(255, 255, 255, 0) 100%);
  transition: 0.3s;
}
@media (min-width: 1024px) {
  .card__thumb::after {
    top: calc(100% - 140px);
 }
}
.card__image {

  width: 100%;
  
  height: 60vh;
  filter: brightness(50%);
  object-fit: cover;
  position: relative;
  transition: 0.5s ease-in-out;

}
.card__caption { 
  position: absolute;
  top: 50%;
  z-index: 1;
  padding: 0 20px;
  color: white;
  transform: translateY(-50%);
  text-align: center;
  transition: 0.3s;
}
.modal_header{
  background-image: url(../../../../data/image/ourexpertise/modal/cyber.jpeg);
background-size: cover;
width: 100%;
background-position: 10%;
}
.modal_header_main h2 {
 
}
.modal_header_main{
  color: white;
  height: 28vh;
  font-size: 2em;
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
  align-items: center;
  align-content: space-around;
  justify-content: flex-start;
  
}
.modal_header_text{
  font-size: 1.3em;
}

ul {
  list-style-type: none;
}
.te{
  font-size: 1.2em !important;
}

.modal_header_maint_UCaas{

}

.modal_header_UCaas{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%),url(../../../../data/image/ourexpertise/modal/UCaas.jpeg);
background-size: cover;
}
.modal_header_telco{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%),url(../../../../data/image/ourexpertise/modal/Telecom-industry.jpeg);
  background-size: cover;
}
.modal_header_telco{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%),url(../../../../data/image/ourexpertise/modal/Telecom-industry.jpeg);
  background-size: cover;

  background-position: 4rem 22rem;

}
.modal_header_clud{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%),url(../../../../data/image/ourexpertise/modal/What_are_the_Types_of_Cloud_Computing_and_Cloud_Services.avif);
  background-size: cover;
}
.modal_header_it{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.5)100%),url(../../../../data/image/ourexpertise/modal/IT-Infrastructure-Management.jpeg);
  background-size: cover;
}
.modal_UCaas_text{
  margin: 3rem;
}
.header_modal_text{
  margin: 1rem;
}
.modal_header_maint_UCaas{
color: white;
}
.modal {

    --bs-modal-header-padding: 7rem 1rem;

}
@media (min-width: 1024px) {
  .card__caption {
    top: calc(100% - 88px);
    transform: unset;
 }
}
.card__title {
  display: -webkit-box;
  max-height: 85px;
  overflow: hidden;
  font-size: 23px;
  line-height: 28px;
  text-shadow: 0px 1px 5px black;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card__snippet {
  display: -webkit-box;
  max-height: 150px;
  margin: 20px 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  transition: 0.5s ease-in-out;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
@media (min-width: 1024px) {
  .card__snippet {
    margin: 60px 0;
 }
}
.card__button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
}
.card__button:hover {
  color: black;
  background-color: white;
}
.disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  background-color: white;
  transform: translateX(-50%);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}
.disclaimer__link {
  color: #755d87;
  text-decoration: none;
}
.mantine-rqmd87 {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 3 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 28px 23px -7px, rgb(0 0 0 / 4%) 0px 12px 12px -7px;
  position: relative;
  outline: 0;
  background-color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  padding: 20px;
  width: 100%;
}
@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  .ourexpertise .header h1 {
    text-transform: uppercase;
    color: black;
    font-size: 1.8em;
}
.mantine-rqmd87 {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 3 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 28px 23px -7px, rgb(0 0 0 / 4%) 0px 12px 12px -7px;
  position: relative;
  outline: 0;
  background-color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  padding: 0px !important;
  width: 100%;
}
.modal_UCaas_text {
  margin: 1rem !important; 
}
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
  .ourexpertise .header h1 {
    text-transform: uppercase;
    color: black;
    font-size: 2em;
}
.modal_UCaas_text {
  margin: 1rem !important; 
}
.mantine-rqmd87 {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 3 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 28px 23px -7px, rgb(0 0 0 / 4%) 0px 12px 12px -7px;
  position: relative;
  outline: 0;
  background-color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1;
  padding: 0px !important;
  width: 100%;
}
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){
  .ourexpertise .header h1 {
    text-transform: uppercase;
    color: black;
    font-size: 3em;
}

  /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  {
  .ourexpertise .header h1 {
    text-transform: uppercase;
    color: black;
    font-size: 3em;
}
  /* 
  /* big landscape tablets, laptops, and desktops */ }