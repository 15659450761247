@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Asap", sans-serif;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
#services{

}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 34px;
  left: 0;
  width: 100%;
}
ul {
  list-style: none;
}

#verticals{
}
#contact{
  background-color: #1b2c49
}
#aboutus{




}
:root {
	--mainColor: white;
	--mainColorLight: white;
	--secondaryColor: #db2b39;
	--textColor: black;
}

header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 97px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: black;
    z-index: 100;
    width: 100%;
}
.logo{
    width: 12rem;
}
nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
    width: 100%;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: center;
}



.button-60 {
    background-color: initial;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
    border-radius: 5px;
    border-style: none;
    box-shadow: rgb(245 244 247 / 25%) 0 1px 1px inset;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 37px;
    line-height: 38px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
}

.button-60:hover {
  opacity: .7;
}


section {


}
section:nth-child(1) {
  color: #e0ffff;
}
section:nth-child(2) {
  color: #42455a;
  background: #e0ffff;
}
section:nth-child(3) {
  color: #e0ffff;
}
section:nth-child(4) {

}
section:nth-child(5) {
  color: #e0ffff;
}
section .container {

}
section h1 {

  margin: 20px;
}
section h2 {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
}
section .text-container {
  display: flex;
}
section .text-container .text-box {
  margin: 20px;
  padding: 20px;
  background: #00c2cb;
}
section .text-container .text-box h3 {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.information_swiper{

  overflow: hidden;
  display: flex;
  color: white;
  top:0;
  position: relative; 
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;

  flex-direction: row;
  align-items: center;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.fdd{
  z-index: 10000;
}

.two_slider h1{
  font-size: 3.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40vh;
  justify-content: flex-end;
}
.two_slider p{
  font-size: 1.9em;
  width: 83%;
  display: flex;
  margin: 0 auto;
  /* height: 48vh; */
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-around;
  padding-top: 2rem;
  padding-bottom: 8rem;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-around;
}
.video-container {
  
}
.slider_header h1{
  font-size: 3em;
}
.one_slider{
  position: absolute;
  z-index: 10000;
  color: white;
}
.two_slider{
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

}
video {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  filter: brightness(40%);

}
.content{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  right: 7%;
flex-direction: column;
justify-content: center;
align-items: center;
color: white;    
}




@media all and (max-width: 319px) {   /* smartphones, iPhone, portrait 480x320 phones */ }
@media all and (max-width: 639px) and (min-width: 320px)  { 
  section .container {
    margin: 0px;
}

section .container {
  margin: 0px;
}
.two_slider h1 {
 
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 1.9em;
  /* height: 24vh; */
  width: 98%;
  justify-content: flex-end;
  align-content: flex-end;

}

.information_swiper p {
font-size: 1.3em;

}

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media all and (max-width: 799px) and (min-width: 640px) { 
  section .container {
    margin: 0px;
}

.information_swiper p {
  font-size: 1.3em;

}
.information_swiper h1 {
font-size: 3em;
}
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media all and (max-width: 1023px) and (min-width: 800px){ 
  .information_swiper p {
    font-size: 1.3em;

}
.information_swiper h1 {
  font-size: 3em;
}
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media all and (min-width: 1024px)  { /* big landscape tablets, laptops, and desktops */ }

